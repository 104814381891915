import Aura from '@primevue/themes/aura'
// @ts-ignore
import { definePreset } from '@primevue/themes'

export default definePreset(Aura, {
  primitive: {
    indigo: {
      50: '#e3ece9',
      100: '#b9cec8',
      200: '#8aaea3',
      300: '#5b8e7e',
      400: '#387563',
      500: '#155d47',
      600: '#125540',
      700: '#0f4b37',
      800: '#0c412f',
      900: '#063020',
      950: '#063020',
    },
  },
  semantic: {
    primary: {
      50: '{indigo.50}',
      100: '{indigo.100}',
      200: '{indigo.200}',
      300: '{indigo.300}',
      400: '{indigo.400}',
      500: '{indigo.500}',
      600: '{indigo.600}',
      700: '{indigo.700}',
      800: '{indigo.800}',
      900: '{indigo.900}',
      950: '{indigo.950}',
    },
    focusRing: {
      color: '{primary.200}',
    },
  },
})
